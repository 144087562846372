import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import NewsView from './news-view'

const NewsController = ({ slug }) => {
  const [loading, setLoading] = useState(false)
  const [news, setNews] = useState([])
  const { i18n } = useTranslation()
  const getNews = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'newsDetail',
      params: {
        slug,
        locale: i18n.language,
      },
    })
      .then((resp) => {
        setNews(resp.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getNews()
  }, [])

  useEffect(() => {
    getNews()
  }, [slug, i18n.language])

  const viewProps = {
    data: news,
    loading,
  }
  return <NewsView {...viewProps} />
}

export default NewsController
